<template>
  <section class="pa-5">
    <h1>ขั้นตอนเปิดใช้งาน Crypto-Mation Trading Bot</h1>
    <v-divider class="pb-2 mt-2"></v-divider>
    <center>
      <LazyYoutube
        ref="lazyVideo"
        src="https://www.youtube.com/embed/3x3gsnEGyIo"
      />
    </center>

    <p class="caption ma-0">Last update: 29/04/2565</p>
  </section>
</template>

<script>
import { LazyYoutube } from "vue-lazytube";
export default {
  name: "RegisterCryptoMationBot",
  data: () => ({}),
  methods: {},
  computed: {},
  components: { LazyYoutube },
};
</script>
<style scoped>
.image-center-view {
  display: flex;
  justify-content: center;
}
</style>
