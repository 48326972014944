<template>
  <section class="pa-5">
    <h1>
      ขั้นตอนเชื่อมต่อระหว่าง Crypo Mation Training Bot กับ Binance Exchange
    </h1>
    <h3>1. เชื่อมต่อ Binance เมื่อเริ่ม Subscription Training Bot</h3>
    <p>
      เมื่อกดปุ่ม Buy now จะมีส่วนในเพิ่มการเชื่อมต่อกับ Binanace Exchange
      ให้คัดลอก <b> API KEY </b> และ <b> Secret Key </b> หรืออัพโหลดรูป QRcode
      จะเติมให้อัตโนมัติจากนั้นจะกด Check Binance Key
      เพื่อตรวจสอบข้อมูลของท่านถูกต้องหรือไม่
    </p>
    <div class="image-center-view">
      <v-img
        class="my-2"
        :src="require('../../assets/docs/connect-bot-1.png')"
        contain
        max-width="500"
      />
    </div>
    <h3>2. เชื่อมต่อ Binance ใหม่กับ Training bot ที่เปิดใช้งานอยู่</h3>
    <p>
      ไปยังที่เมนู My Subscriptions (มุมขวาไอคอนชื่ออีเมลล์) > เลือก Training
      bot ที่ต้องการเปลื่ยน <b> API KEY </b> และ <b> Secret Key </b>
    </p>
    <div class="image-center-view">
      <v-img
        class="my-2"
        :src="require('../../assets/docs/connect-bot-2.png')"
        contain
        max-width="500"
      />
    </div>

    <p class="caption ma-0">Last update: 23/03/2565</p>
  </section>
</template>

<script>
export default {
  name: "CryptoMationConnectBot",
  data: () => ({}),
  methods: {},
  computed: {},
};
</script>
<style scoped>
.image-center-view {
  display: flex;
  justify-content: center;
}
</style>