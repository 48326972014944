<template>
  <section class="pa-5">
    <h1>ขั้นตอนการยืนยันตัวบน Binance Exchange</h1>
    <h3>ยืนยันผ่านแอปพลิเคชัน (โทรศัพท์)</h3>
    <p>
      ดาว์นโหลดแอฟพลิเคชัน Binance บน
      <a
        href="https://apps.apple.com/th/app/binance-buy-bitcoin-securely/id1436799971"
      >
        <span class="blue--text text--lighten-1">App Store (IOS)</span>
      </a>
      หรือ
      <a
        href="https://play.google.com/store/apps/details?id=com.binance.dev&hl=th&gl=US"
      >
        <span class="blue--text text--lighten-1">Play Store (Android)</span>
      </a>
    </p>
    <p> 1. กดปุ่ม SignUp / Login จากนั้นเข้าสู่ระบบ Binance ที่สมัครบัญชีไว้แล้ว</p>
    <p> 2. ระบบจะให้ยืนยันการเข้าสู่ระบบโดยรหัสยืนยันมาที่โทรศัพท์และอีเมลล์ (อาจจะอย่างนั้นอย่างหนึ่ง) ซึ่งเราจะกด "Send Code" ให้ Binance ส่งรหัสยืนยันมาให้กับเรา </p> 
    <p> 3. เมื่อยืนยันการเข้าระบบได้แล้วกดปุ่ม Verify Account เพิ่อยืนยันตัวตน </p>
     <div class="image-center-view">
      <v-img
        class="my-2"
        :src="require('../../assets/docs/verify-binance-1.png')"
        contain
      />
    </div>
    <p> 4. ตรวจสอบสัญลักษณ์ว่าเป็นประเทศไทยหรือไม่ ต้องเป็นประเทศไทยเท่านั้น แล้วกัน "Start Now"</p>
    <p> 5. <b class="red--text">ใส่ชื่อ-นามสกุลและวันเดือนปีเกิด(First Name/Last Name/Date of Birth) เป็นภาษาอังกฤษและตรงกับชื่อและวันเกิดตรงกับบัตรประชนชนเท่านั้น (ไม่สามารถแก้ไขภายหลังได้)</b> ส่วนชื่อกลาง ถ้ามีให้ทำเหมือนกันแต่ไม่มีให้ว่างไว้ </p>
    <p> 6. กรอกที่อยู่เป็นภาษาอังกฤษ (Residential Address) รหัสไปรษณี (Postal Code) และเมืองที่อยู่ (City) เป็นภาษาอังกฤษ </p>
     <div class="image-center-view">
      <v-img
        class="my-2"
        :src="require('../../assets/docs/verify-binance-2.png')"
        contain
      />
    </div>
    <p> 7. เลือกเอกสารการยืนยันตัวตนเป็นประจำตัวประชาชน (Government-Issued ID Card) หรือ พาสปอร์ต (Passport) หรือใบขับขี่ (Driver's License) </p>
    <p> 8. คำแนะนำการถ่ายเอกสารจะต้องภาพถ่ายบัตรเป็นใบ, สีชัดเจน </p>
    <p> 9. เลื่อนลงมาด้านล่างระบบจะให้ถ่ายรูปภาพหรือเลือกรูปภาพด้านหน้าของเอกสาร (Front of Document) และด้านหลังของเอกสาร (Back of Document) </p>
     <div class="image-center-view">
      <v-img
        class="my-2"
        :src="require('../../assets/docs/verify-binance-3.png')"
        contain
      />
    </div>
    <p> 10. ถัดไประบบจะให้ถ่ายรูปตัวเอง (Selfie) พยายามจัดแสงให้เห็นหน้าชัดเจน</p>
    <p> 11. ถัดไประบบยืนยันตัวตน โดยให้คำแนะนำดังนี้ ไม่สวมใส่หมวกและแว่น, ไม่ใช้ filter ถ่ายรูปและอยู่ในที่มีแสงเพียงพอ</p>
    <p> 12. ทำตามคำแนะนำด้านล่างขณะยืนยันตัวตน เพียงเท่านี้เป็นอันเสร็จเรียบร้อย</p>
     <div class="image-center-view">
      <v-img
        class="my-2"
        :src="require('../../assets/docs/verify-binance-4.png')"
        contain
      />
    </div>
    <p> ระบบจะใช้เวลาในการพิจารณาสักครู่ การยืนยันจะเห็นผลดังนี้ ถ้าเป็นสีเหลืองคือรอต่อไป สีเขียวคือผ่านสามารถใช้งานได้และสีแดงอาจจะเกิดข้อผิดพลาดระบบจะแจ้งเตือนว่าไม่ผ่านในขั้นตอนใดซึ่งส่งผลซ้ำได้ </p>
     <div class="image-center-view">
      <v-img
        class="my-2"
        :src="require('../../assets/docs/verify-binance-5.png')"
        contain
        max-width="200"
      />
    </div>
    <p class="caption ma-0">Last update: 22/03/2565</p>
  </section>
</template>

<script>
export default {
  name: "BinanceVerifySection",
  data: () => ({}),
  methods: {},
  computed: {},
};
</script>
<style scoped>
.image-center-view {
  display: flex;
  justify-content: center;
}
</style>